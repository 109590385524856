import React, { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import Seo from '~/components/Seo';
import Layout from '~/components/Layout';

const FourOhFourContainer = styled.div`
  @media screen and (min-width: 1020px) {
    min-height: 100vh;
  }
`;

function Custom404() {
  useEffect(() => {
    if (typeof window?.dataLayer?.push !== 'undefined') {
      window.dataLayer.push({
        event: 'page not found',
        pageUrl: window?.location?.pathname
      });
    }
  }, []);

  return (
    <Layout>
      <Seo title="404 | Proof" />
      <FourOhFourContainer className="py-20 four-oh-four md:py-40 bg-tertiary flex flex-col justify-center">
        <div className="container pb-6">
          <div className="items-center justify-between md:flex">
            <div className="mb-8 md:w-1/2 md:mb-0">
              <article className="mx-auto md:ml-0 max-w-md text-center md:text-left">
                <h6 className="mb-2 u-s1">404</h6>
                <h1 className="mb-3 text-black u-h2">Uh oh, we can't find that page</h1>
                <p className="mt-0 mb-2 u-p2">Let's get you back somewhere safe</p>
                <Link href="/">
                  <a className="mt-4 btn">Back home</a>
                </Link>
              </article>
            </div>
            <div className="md:w-1/2">
              <Image src="/images/404.png" alt="404" draggable="false" width={616} height={296}></Image>
            </div>
          </div>
        </div>
      </FourOhFourContainer>
    </Layout>
  );
}

export default Custom404;

export const getStaticProps = async () => {
  return {
    props: {}
  };
};
